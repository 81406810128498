"use client";
import React from "react";

type CollapsibleSidebarProps = {
  children: React.ReactNode;
};

const CollapsibleSidebar: React.FC<CollapsibleSidebarProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  return (
    <>
      <div
        data-is-open={isOpen}
        className="fixed z-50 sm:hidden data-[is-open=false]:left-5 top-5 data-[is-open=true]:right-5"
      >
        <button
          type="button"
          className="btn btn-sm btn-ghost btn-square bg-base-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-base-content"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div
        data-is-open={isOpen}
        className="overflow-y-auto overflow-x-visible pb-12 transform w-full translate-x-0 fixed z-40 flex h-full flex-col justify-between border-r border-base-content/10 bg-base-100 transition-all sm:w-64 sm:translate-x-0 data-[is-open=false]:sm:w-64 data-[is-open=false]:w-0"
      >
        <div className="p-4 h-full flex flex-col gap-y-2">{children}</div>
      </div>
    </>
  );
};

export default CollapsibleSidebar;
